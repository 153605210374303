


























import { Vue, Component } from "vue-property-decorator";
import {
    Swiper as SwiperClass,
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
SwiperClass.use([
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow
]);
Vue.use(getAwesomeSwiper(SwiperClass));

@Component({
    components: {}
})
export default class Carousel extends Vue {
    swiperOption = {
        direction: "horizontal",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // slidesPerView: 7,
        // spaceBetween: 10,
        centeredSlides: false,
        centeredSlidesBounds: false,
        loop: true,
        speed: 800,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
            hide: true
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            hideOnClick: true
        }
    };

    opt: any[] = []; //carousel图片数据

    get swiperData() {
        const that = this;
        let opt = [
            // {
            //     url:
            //         this.$i18n.locale == "zh"
            //             ? "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/besttt_banner_zh.jpg"
            //             : "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/besttt_banner_zh.jpg"
            // }
            // {
            //     url:
            //         this.$i18n.locale == "zh"
            //             ? "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/bestech_banner_1_zh.jpg"
            //             : "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/bestech_banner_1_en.jpg",
            //     click(item: any, i: any) {
            //         that.$router.push({
            //             name: "register",
            //             params: { step: "0" }
            //         });
            //     }
            // },
            // {
            //     url:
            //         this.$i18n.locale == "zh"
            //             ? "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/bestech_banner_2_zh.jpg"
            //             : "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/bestech_banner_2_en.jpg"
            // }
        ];
        opt = this.opt;

        return opt;
    }
    iskeepAutoplay: boolean = true;

    handleImg(item: any, i: any) {
        item.click ? item.click(item, i) : false;
        // if (item.click) {
        //     item.click(item, i);
        // }
    }

    getData() {
        const getopt = this.$api.apiList.getCarousel;
        const opt = getopt();

        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            this.opt = JSON.parse(res.data.data.content);
        });
    }

    mounted() {
        this.getData();
    }
}
