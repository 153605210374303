




























import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
  },
})
export default class CollectRange extends Vue {
  opt: any[] = [
    {
      title: "一、“技术转移组织机构”（计划集中发布约40项）",
      list: [
        "一是以“科技成果转移转化与高质量创新资源供给实践”为主题，面向海内外高等院校、科研院所等征集典型案例，案例主体聚焦高校技术转移办公室运营模式、科研院所科技成果转化工作流程与成功经验、新型研发机构机制建设等。",
        "二是以“创新要素市场化配置、构建产业创新生态体系”为主题，面向海内外科技服务、科技中介、孵化器、加速器、律师事务所、投资机构、科技成果转移转化平台、开放创新平台、数字化平台等专业机构，以及知名国际科技交流领域会议、展览、奖项、赛事等。",
      ],
    },
    {
      title: "二、“重点领域技术转移项目”（计划集中发布约60项）",
      list: [
        "一是以“信息通讯与互联网”为关键领域，关注人工智能、智慧城市、金融科技、超高清视频和新型显示、先进通信网络、增强/虚拟/混合现实、网络安全等具体技术，征集、遴选典型成功案例。",
        "二是以“生物医药与大健康”为关键领域，关注创新药、抗体药物、细胞基因疗法、高端制剂、干细胞与再生医学等具体技术，征集、遴选典型成功案例。",
        "三是以“绿色技术与双碳经济”为关键领域，关注清洁能源、绿色建筑、绿色交通、先进储能技术、智能电网、新能源汽车等具体技术，征集、遴选典型成功案例。",
      ],
    },
  ];
}
