


























import { Vue, Component, Prop } from "vue-property-decorator";
import { Dialog, Switch } from "element-ui";
import "@/components/al/ImportJs";
@Component({
    components: {
        "el-dialog": Dialog,
    },
})
export default class VideoDialog extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: () => {
            return false;
        },
    })
    isShow: any;

    handleDialog() {
        this.$emit("update:isShow", false);
    }

    video_playerId = "video_player"; //视频播放器id
    video_coverImg =
        "http://www.gw-video.com.cn/snapshot/41d31d30ad5a4f8cb2f65ffced3fa22800005.jpg"; //视频封面
    video_url =
        "http://www.gw-video.com.cn/video/319539ec-179123ac200-0000-0000-013-83ff1.mp4"; //视频url

    //外部js文件加载完毕钩子
    isLoaded(e: any) {
        this.setVideo(this.video_playerId, this.video_url, this.video_coverImg);      
    }

    //初始化视频播放器
    setVideo(playerId: any, url: any, coverImg: any) {
        //初始化视频
        // @ts-ignore
        let player = new Aliplayer(
            {
                id: playerId,
                width: "100%",
                height: "100%",
                cover: coverImg,
                source: url,
                autoplay: true,
                isLive: false,
                rePlay: true,
                playsinline: true,
                preload: true,
                controlBarVisibility: "hover",
                useH5Prism: true,
            },
            function (player: any) {
                console.log("视频播放器创建好了。");
            }
        );
        // player.pause()
        player.mute();
        player.play()

    }

    destroyed() {

    }
}
