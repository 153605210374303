


























import { Vue, Component, Prop } from "vue-property-decorator";
import {
    Swiper as SwiperClass,
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
SwiperClass.use([
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow,
]);
Vue.use(getAwesomeSwiper(SwiperClass));
@Component({})
export default class List extends Vue {
    @Prop({ type: Array, required: true, default: () => [] })
    list: any;

    /**项目轮播参数 */
    swiperOption: {} = {
        direction: "vertical",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        slidesPerView: 6,
        spaceBetween: 10,
        centeredSlides: false,
        centeredSlidesBounds: true,
        loop: true,
        speed: 3000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
        },
    };

    // list: any[] = [];

    iskeepAutoplay: boolean = true;

    handelCaseItem(e: any, item: any, index: any) {
        // this.$router.push({ name: "project2020", params: { pid: item.id } });
    }
}
