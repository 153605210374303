




































import { Vue, Component, Watch } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem
    }
})
export default class RegistDataShow extends Vue {
    opt_temp:any[]=[]   //数据模板
    opt: any[] = []; //数据
    canSee: boolean = false; //组件是否在可视范围内

    timer: any;

    async getData() {
        const getopt = this.$api.apiList.getRegistData;
        const opt = getopt();

        let res = await this.$axios({
            method: opt.method,
            url: opt.url,
            headers: opt.headers
        }).then((res: any) => {
            this.opt_temp = JSON.parse(res.data.data.content);
            this.opt = JSON.parse(res.data.data.content);
            return JSON.parse(res.data.data.content);
        });

        return res;
    }

    scrollHandle() {
        const offset = this.$el.children[1].getBoundingClientRect();
        const offsetTop = offset.top;
        const offsetBottom = offset.bottom;
        // const offsetHeight = offset.height;
        // 进入可视区域
        if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
            this.canSee = true;
            // console.log("进入可视区域");
        } else {
            this.canSee = false;
            // console.log("移出可视区域");
        }
    }

    @Watch("canSee")
    canSeeChange(nval: any, oval: any) {
        if (nval == true) {
            for (let index = 0; index < this.opt.length; index++) {
                const element = this.opt[index];
                this.numberAdd(this.opt_temp[index].number, index).then(() => {
                    // clearTimeout(this.timer);
                    // this.timer = "";
                });
            }
        } else {
            // clearTimeout(this.timer);
            // this.timer = "";
            this.clear()
        }
    }

    async numberAdd(aimNum: number, key: any) {
        this.opt[key].number = 0;
        const numDiffer = aimNum - 0;
        let timer: any;

        const add = () => {
            timer = setTimeout(() => {
                if (this.opt[key].number < aimNum) {
                    if (aimNum - this.opt[key].number >= 400)
                        this.opt[key].number = this.opt[key].number + 30;
                    else this.opt[key].number++;
                    add();
                } else {
                    clearTimeout(timer);
                    timer = "";
                }
            }, 4);
        };

        add();

        // clearTimeout(timer);
        // timer = "";
    }

    clear() {
        let end = setTimeout(function() {}, 1);
        let start = end - 100 > 0 ? end - 100 : 0;
        for (var i = start; i <= end; i++) {
            clearTimeout(i);
        }
    }

    mounted() {
        this.getData().then(() => {
            window.addEventListener("scroll", this.scrollHandle, true);
            this.scrollHandle();
        });
    }

    destroyed() {
        window.removeEventListener("scroll", this.scrollHandle, true);
        this.clear();
        // clearTimeout(this.timer);
        // this.timer = "";
    }
}
