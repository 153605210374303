


























































import { Vue, Component } from "vue-property-decorator";

import { VideoDialog } from "@/components/home_2022/videoDialog";
import Carousel from "@/components/home_2022/Carousel.vue";

import { CaseListSwiper } from "@/components/home_2022/caseListSwiper";

// import WhatsBesttt from "@/components/home_2022/WhatsBesttt.vue";
import { CollectRange } from "@/components/home_2022/collectRange";

import Contact from "@/components/home_2022/Contact.vue";

import { RegistDataShow } from "@/components/home_2022/registDataShow";
import { Process } from "@/components/home_2022/process";

import { ReleaseShow } from "@/components/home_2022/releaseShow";

import "@/components/al/ImportJs";
@Component({
    components: {
        carousel: Carousel,
        CaseListSwiper,
        // WhatsBesttt,
        process: Process,
        Contact,
        RegistDataShow,
        CollectRange,
        VideoDialog,
        ReleaseShow
    },
})
export default class Home extends Vue {
    videoDialogVisible: boolean = false;
}
