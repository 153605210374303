

























import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import { ListSwiperScroll } from "@/components/list-swiper-scroll";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
        ListSwiperScroll,
    },
})
export default class CaseListSwiper extends Vue {
    list: any[] = [];

    getList(type_id?: number) {
        type_id = type_id ? type_id : 0;
        const getOpt = this.$api.apiList.getCaseList;
        const opt = getOpt(type_id);

        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        })
            .then((res: any) => {
                this.list = res.data.data;
            })
            .catch(() => {});
    }

    goCaseList = () => {
        this.$router.push({name:"case-list"})
    };

    mounted() {
        this.getList();
    }
}
