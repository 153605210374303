































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
  },
})
export default class Process extends Vue {
  process: any[] = [
    { content: "1. “百佳案例”评审坚持“客观、公正、透明、可信”的原则" },
    { content: "2. 组成“评审专家组”，建立“百佳案例”评审组织实施工作机制" },
    { content: "3. 沟通各协办单位征求意见，修订完善评审指标体系" },
    { content: "4. 组织登录、专家组织、审评打分以及信息发布" },
    { content: "5. 组织线下研讨会和评审会，交流专家组意见" },
  ];
}
